@font-face {
  font-family: "Inter Black";
  src: url("../fonts/static/Inter-Black.ttf");
}

@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/static/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter Light";
  src: url("../fonts/static/Inter-Light.ttf");
}

$font-family-sans-serif: "Inter Black", sans-serif;
$primary: #ed6b6e;
$secondary: #333399;
$body-bg: #eef1f9;
$navbar-light-color: $secondary;
$nav-link-color: $secondary;
$nav-link-hover-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "legal";

html,
body {
  overflow-x: hidden;
}

p,
.card-text {
  font-family: "Inter Light", sans-serif;

  p {
    font-family: "Inter Black", sans-serif;
  }
}

#accept-label {
  color: $secondary;
  cursor: pointer;
}

h2.text-primary {
  text-shadow: 21px 21px 30px rgba(237, 107, 110, 1);
}
h2.text-secondary {
  text-shadow: 21px 21px 30px rgba(51, 51, 153, 1);
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
}

.highlighted-primary {
  color: $primary;
  // text-shadow: 21px 21px 30px rgba(237, 107, 110, 1);
  font-size: 1.4rem;
}

section h2,
section p {
  text-align: center;
}

#main-nav {
  border-bottom: 1px solid $primary;

  .navbar-logo {
    height: 30px;
  }

  .navbar-nav {
    align-items: center;
    //font-size: 0.9rem;

    button {
      //font-size: 0.9rem;
      border-radius: 0;
    }

    #contact-us-button {
      & > span:first-child {
        font-size: 0.8rem;
        color: $primary;
      }
    }
  }
}

.btn {
  &:hover {
    color: white;
  }
}

.modal .btn {
  color: white;
}

header {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(../assets/img/backgrounds/bg1_2k.jpg);
  background-size: cover;
  background-position-x: center;
  position: relative;
}

#header-logo {
  position: relative;
  z-index: 10;

  #e1 {
    animation: e1-scale-x 1s ease-in-out;
  }

  #e2 {
    animation: e1-scale-x 1.2s ease-in-out;
  }

  #e3 {
    animation: e1-scale-x 1.4s ease-in-out;
  }
}

@keyframes e1-scale-x {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

#header-particles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#overview {
  background: rgb(238, 241, 249);
  background: linear-gradient(
    180deg,
    rgba(238, 241, 249, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  #overview-text {
    max-width: 850px;

    & > .highlighted-primary {
      font-size: 1.1rem;
    }
  }

  #overview-img {
    max-width: 900px;
  }
}

#technology {
  background: rgb(238, 241, 249);
  background: linear-gradient(
    180deg,
    rgba(238, 241, 249, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  #technology-text {
    max-width: 811px;
  }

  .btn {
    color: white;
  }
}

#saas-engine {
  background: rgb(238, 241, 249);
  background: linear-gradient(
    180deg,
    rgba(238, 241, 249, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

#security {
  border-top: 1px solid $primary;
  background: rgb(238, 241, 249);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 241, 249, 1) 100%
  );
}

.card {
  border-radius: 1.25rem;
}

.vertical-divider {
  border-left: 2px solid $primary;
  animation: vertical-divider-reveal 800ms forwards ease;
}

.saas-row {
  flex: 1;
}

.saas-arrow {
  padding: 0 2rem;
}

.accordion-body {
  font-family: "Inter Light", sans-serif;
}

.accordion-button {
  color: $secondary;
}

@keyframes vertical-divider-reveal {
  from {
    height: 0px;
  }

  to {
    height: 50px;
  }
}

.divider {
  position: relative;

  .custom-shape-divider-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 25px;
  }

  .custom-shape-divider-top .shape-fill {
    fill: #ffffff;
  }

  .custom-shape-divider-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 25px;
  }

  .custom-shape-divider-bottom .shape-fill {
    fill: #ffffff;
  }
}

.divider-1 {
  height: 50vh;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(../assets/img/divider/divider-1.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  .custom-shape-divider-bottom .shape-fill {
    fill: rgba(238, 241, 249, 1);
  }
}

.divider-2 {
  // height: 50vh;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url(../assets/img/divider/divider-2.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.comparison-card {
  &__col {
    max-width: 90%;
    flex: 1;

    &__title {
      color: $primary;
    }

    &__sub {
      font-size: 1.2rem;
      color: $secondary;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 2rem 0;

    & > img {
      width: 59px;
    }

    & > p {
      margin: 0 2rem;
    }
  }
}

.comparison-info {
  &__item {
    flex: 1;
    -webkit-box-shadow: 0px 8px 22px 5px #bababa;
    box-shadow: 0px 8px 22px 5px #bababa;

    h2 {
      font-size: 1.5rem;
    }

    &:first-child {
      background-color: white;
      color: $primary;
      border-radius: 64px 0px 0px 64px;
    }

    &:last-child {
      background-color: $primary;
      color: white;
      border-radius: 0px 64px 64px 0px;

      button:hover {
        color: $primary;
      }
    }
  }
}

footer {
  .contact-item {
    padding: 0.5rem 1rem;

    & > span,
    & > a {
      display: block;
    }
  }
}

@media screen and (max-width: 1200px) {
  #main-nav .navbar-nav {
    padding: 1rem 0;
    align-items: flex-start;
  }
}

@media screen and (max-width: 576px) {
  #main-nav .navbar-logo {
    height: 20px;
  }

  #contact .footer-logo {
    margin: 1rem 0;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  #technology .saas-arrow {
    margin: 3rem 0;
    transform: rotateZ(90deg);
    transition: transform 200ms linear;
  }

  .comparison-card__col__title,
  .comparison-card__col__sub {
    text-align: center;
  }

  .comparison-card__item {
    flex-direction: column;

    & > p {
      margin: 1rem 0;
      text-align: center;
    }
  }

  .comparison-info {
    flex-direction: column;

    &__item:first-child {
      border-radius: 64px 64px 0px 0px;
    }

    &__item:last-child {
      border-radius: 0px 0px 64px 64px;
    }
  }
}
