.legal-container {
  h1:first-child {
    font-size: 3.5rem;
    margin: 3rem 0;
    text-shadow: 21px 21px 30px rgba(237, 107, 110, 1);
  }

  h1, h3, h5 {
    margin-top: 1.5rem;
    font-size: 2rem;
    color: $primary;
  }

  h2, h4, h6 {
    margin-top: 1.5rem;
    color: $secondary;
    font-size: 1.2rem;
  }
}